import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { extract } from '../../utils/graphql'
import { localizePath } from '../../../config/i18n'

import EmptyPage from '../../components/Templates/EmptyPage'
import WysiwygContent from '../../components/General/WysiwygContent'
import NoTranslation from '../../components/Templates/NoTranslation'
import HubspotForm from '../../components/General/HubspotForm'
import { withPreview } from 'gatsby-source-prismic'
import ScriptEmbed from '../../components/General/ScriptEmbed'

const ContactPage = ({ data, pageContext }) => {
  const { prismicContactPage } = data
  const page = prismicContactPage
  if (!page) return <NoTranslation></NoTranslation>

  const hydratedData = {
    lang: pageContext.locale,
    seo: extract.seo(page),
    mantelData: extract.mantel(page, {
      isThick: true,
      parent: {
        path: localizePath(pageContext.locale, `/get-in-touch`),
        text: 'Get in touch',
      },
    }),
  }
  return (
    <EmptyPage {...hydratedData}>
      <div className="-mt-6 lg:mt-0">
        <ScriptEmbed
          embed={`<script type="text/javascript" src="https://form.jotform.com/jsform/232776352754161"></script>`}
        />
      </div>
      <div className="mt-4">
        <WysiwygContent>
          {page.data.content && page.data.content.html && (
            <div
              dangerouslySetInnerHTML={{ __html: page.data.content.html }}
            ></div>
          )}
        </WysiwygContent>
      </div>
    </EmptyPage>
  )
}

export default withPreview(ContactPage)

export const pageQuery = graphql`
  query ContactUsByLocale($locale: String!) {
    prismicContactPage(lang: { eq: $locale }) {
      data {
        title {
          text
        }
        seo_keywords {
          keyword {
            text
          }
        }
        seo_description {
          text
        }
        mantel_title {
          text
        }
        mantel_content {
          html
        }
        content {
          html
        }
        hubspot_form_id
      }
    }
  }
`
